@import "~antd/dist/antd.css";
@import "~react-quill/dist/quill.snow.css";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap");

body {
  font-family: "Raleway", sans-serif;
  background-color: #f0f1ff;
  color: #403952;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", serif;
}
h1.ant-typography,
.ant-typography h1,
h2.ant-typography,
.ant-typography h2,
h3.ant-typography,
.ant-typography h3,
h4.ant-typography,
.ant-typography h4,
h5.ant-typography,
.ant-typography h5,
h6.ant-typography,
.ant-typography h6 {
  color: #403952;
}
.layout {
  background-color: #f0f1ff;
}
th.column-money,
td.column-money {
  text-align: right !important;
}

.menuBar {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menuCon {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.logo {
  flex: 1;
  justify-content: flex-start;
  display: flex;
}
.leftMenu {
  flex: 1;
}
.rightMenu {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}
.rightMenu .ant-dropdown-link {
  max-height: 58px;
  margin: 5px;
  .icon-menu-dropdown {
    width: 8px;
    height: 8px;
    margin-left: 3px;
  }
}
.auth-nav .logo {
  justify-content: center;
}
.auth-nav .barsMenu {
  display: none;
}
.auth-nav .logo a {
  max-width: 24px;
  overflow: hidden;
  margin-left: 0;
}
.auth-nav .leftMenu,
.auth-nav .rightMenu {
  display: none;
}
.menuCon .ant-menu-item {
  padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}
.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}
.menuCon .ant-menu-horizontal {
  border-bottom: none;
}
.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 16px;
  display: none;
  background: none;
}
.App .ant-btn.barsMenu {
  color: #fff;
}
.barsMenu:hover,
.App .ant-btn.barsMenu:hover {
  background-color: transparent;
  color: #8fafcc;
}
.barsBtn {
  font-size: 24px;
  position: relative;
}
.ant-drawer-body {
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fafaff;
}
@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
    margin-right: -20px;
  }
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
  }
  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
}

.App {
  font-family: "Roboto", sans-serif;
  background-color: #f0f1ff;
  height: 100%;
}
.App .ant-menu {
  text-align: center;
}
.App .ant-menu-horizontal > .ant-menu-item,
.App .ant-menu-horizontal > .ant-menu-submenu,
.App .ant-menu-horizontal > .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
}
.App .rightMenu .ant-menu-horizontal a {
  color: #fff;
}
.App .ant-menu-horizontal > .ant-menu-item:hover a,
.App .ant-menu-horizontal > .ant-menu-item:hover {
  border-color: transparent;
}

.App .ant-menu-horizontal > .ant-menu-item-selected,
.App .ant-menu-horizontal > .ant-menu-item-selected:hover {
  border-bottom: 2px solid #fff;
}
.App .ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #fff;
}
.App .ant-table-body {
  overflow-x: auto;
}
.App .ant-table table {
  border-radius: 21px;
}
.App .ant-table-thead > tr > th {
  background: #EBEDEE;
  font-size: 12px;
  font-weight: bold;
  color: #27292A;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 1;
}
.App .ant-table-pagination.ant-pagination {
  margin: 16px;
  display: flex;
  justify-content: center;
  float: none;
}
.App
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.App
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.App .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.App .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: #fcfcff;
}
.App .ant-btn-download {
  width: 226px;
  background-color: #0089ff;
}
.App .ant-dropdown-link {
  color: #fff;
  font-weight: bold;
}
.App .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 21px;
}
.App .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 21px;
}
.App .ant-tag {
  font-weight: bold;
}
.App .ant-btn {
  color: #8fafcc;
  border-color: transparent;
}
.App .ant-btn-primary {
  background-color: #2c9dff;
  color: #fff;
}
.App .ant-btn:hover {
  color: #0089ff;
  background-color: #fff;
}

.App .ant-btn-primary:hover {
  color: #fff;
  background-color: #45a8ff;
}

.App .ant-form-item-label {
  line-height: 24px;
}
.App .ant-checkbox-wrapper {
  line-height: 1.5;
}
.App .ant-form-item-label > label::after {
  content: none;
}
.App .ant-card.blue-inverse {
  background-color: #0089ff;
}

.App .ant-card.green .ant-typography {
  color: #00cc8b;
}
.App .ant-card.purple .ant-typography,
.App .ant-card.purple .ant-list-item-meta-title,
.App .ant-card.purple .ant-list-item-meta-description {
  color: #5b2ad0;
}
.App #description {
  line-height: 1;
}
.App .ant-card.blue-inverse .ant-typography {
  color: #fff;
}

.App .ant-list-item {
  padding: 8px 0;
}

.App .ant-empty-image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
.App .ant-empty-image {
  height: auto;
}
.App .ant-description {
  min-height: 185px;
}
.App .ant-input,
.App .ant-select,
.App .ant-select-selection--single {
  height: 48px;
  border-radius: 0;
  border-color: #eeedf2;
  background: #fbfaff;
  font-size: 16px;
  color: #403952;
  margin: 0;
}
.App .ant-input,
.App .ant-select {
  border: 1px solid #eeedf2;
}
.App textarea.ant-input {
  height: inherit;
}
.App .ant-select-selection.ant-select-selection--multiple {
  border-radius: 0;
  padding: 0;
  border-color: #eeedf2;
  background-color: #fbfaff;
}
.App .ant-select-selection__rendered {
  font-size: 16px;
  color: #403952;
  margin: 0;
  height: 48px;
  padding: 0 11px;
  line-height: 48px;
}
.App .input-variants input {
  background-color: #ffffff;
  border: 1px solid #c4c9cb;
  border-radius: 3px;
  color: #4f5354;
  height: 39px;
}
.App .input-variants input::placeholder {
  color: #9da5a8;
}
.App .select-variants {
  min-width: 172px;
  border: none;
  height: 39px;
}
.App .select-variants .ant-select-selection--single {
  background-color: #ffffff;
  height: 100%;
  border: 1px solid #c4c9cb;
  border-radius: 3px;
}
.App .select-variants .ant-select-selection__rendered {
  padding-right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  width: calc(100% - 39px);
}
.App .select-variants .ant-select-selection__placeholder {
  top: 42%;
}
.App .select-variants .ant-select-selection-selected-value {
  height: auto;
  line-height: 20px;
}
.App .select-variants .ant-select-arrow {
  padding: 0 8px;
  border-left: 1px solid #c4c9cb;
  height: 100%;
  top: 0 !important;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  display: flex;
  right: 0;
  width: 39px;
}
.App .select-variants .ant-select-selection__placeholder {
  color: #9da5a8;
  padding-right: 0;
}
.App .ant-select-selection__placeholder {
  padding: 0 11px;
}
.App .ant-select-selection--multiple .ant-select-selection__choice {
  border-radius: 16px;
  height: 32px;
  font-size: 12px;
  background-color: rgba(143, 175, 204, 0.7);
  border-color: transparent;
  color: white;
  font-weight: bold;
  line-height: 32px;
  margin-top: 6px;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.App .tag-variant {
  height: 39px;
  background: #ffffff;
  border: 1px solid #c4c9cb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 8px;
}
.App button.selected-tag-variant {
  background-color: #ebedee;
  border: none;
  border-radius: 4px;
  height: 24px;
  min-width: 37px;
  margin-right: 5px;
}
.App button.selected-tag-variant span {
  color: #4f5354;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  font-family: Nunito Sans, serif;
  font-style: normal;
  text-transform: uppercase;
}
.App button.selected-tag-variant:after {
  content: "\2715";
  color: #4f5354;
  margin-left: 8px;
}
.App .ant-steps-item-icon {
  margin-right: 0;
}
.ant-steps-item-description {
  font-family: Nunito Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px !important;
  line-height: 16px;
  color: #9da5a8;
  margin-top: 7px;
}
.ant-steps-item-container {
  position: relative;
}
.App .ant-steps-item-finish .ant-steps-item-description {
  color: #27292a !important;
}
.ant-steps-item-active .ant-steps-item-description {
  color: #1c6fe8 !important;
}
.App .ant-input-number.input-variants {
  padding: 0 !important;
  border: none;
  height: 39px;
}
.App .product-inventory {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.public-DraftEditor-content,
.public-DraftEditorPlaceholder-inner {
  font-family: Nunito Sans, serif;
}
.public-DraftEditorPlaceholder-inner::placeholder {
  color: #9da5a8;
}
.EditorToolbar__root___3_Aqz {
  border-bottom: none !important;
}
.App .ant-input::placeholder,
.App .ql-editor.ql-blank::before,
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: #a3a6cc;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}

.App .ant-btn {
  font-weight: bold;
}

.App .auth-container .ant-btn {
  height: 48px;
}

.App .auth-container .ant-btn {
  font-size: 18px;
}

.App .ant-form-item-label > label {
  color: #a492d1;
  font-size: 12px;
  font-weight: bold;
}

.App .ant-form-explain,
.App .ant-form-extra {
  color: #8fafcc;
  font-size: 12px;
}

.App .has-error .ant-form-explain,
.App .has-error .ant-form-split {
  color: #ff00a7;
}

.App .ant-select-selection--single,
.App .ant-select-selection__rendered {
  height: 46px;
  border: none;
}

.App .ant-input:hover,
.App .ant-select:hover,
.App .ant-upload .ant-btn:hover {
  border-color: #0089ff;
}

.App .ant-input:focus,
.App .ant-select:focus {
  border-color: #0089ff;
  box-shadow: 0 0 0 2px rgba(0, 137, 255, 0.2);
}

.App .has-error .ant-input,
.App .has-error .ant-input:hover,
.App .has-error .ant-select,
.App .has-error .ant-select:hover {
  border-color: #ff00a7;
}

.App .ant-form-item-children + .ant-form-explain {
  margin-top: 4px;
  margin-bottom: 4px;
}
.App .ant-upload.ant-upload-select {
  display: block;
}

.App .ant-upload.ant-upload-select {
  display: block;
}

.App .ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}
.App .ant-upload.ant-upload-select-picture-card {
  padding: 0;
  border: none;
  width: 100%;
}
.App .ant-upload .ant-btn {
  flex: 1;
  border-radius: 0;
  box-shadow: none;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: 1px solid #eeedf2;
  border-radius: 0;
  background: #fbfaff;
}

.ant-upload-list-item .anticon-close {
  font-size: 12px;
  color: #a492d1;
}

.App .desciption-container .ql-editor {
  min-height: 120px;
  font-family: "Roboto", sans-serif;
}

.App .ql-editor {
  min-height: 100px;
  font-family: "Roboto", sans-serif;
}

.App .ql-toolbar.ql-snow {
  font-family: "Roboto", sans-serif;
}

.App .quill.ant-input {
  height: inherit;
  padding: 0;
}

.App .ql-toolbar.ql-snow {
  border: none;
  background-color: #fff;
}

.App .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none;
}

.App .ant-upload .ant-btn:hover {
  color: #0089ff;
}
.App .ant-table-row.test {
  cursor: pointer;
}
.App .ant-btn-primary-disabled,
.App .ant-btn-primary.disabled,
.App .ant-btn-primary[disabled],
.App .ant-btn-primary-disabled:hover,
.App .ant-btn-primary.disabled:hover,
.App .ant-btn-primary[disabled]:hover,
.App .ant-btn-primary-disabled:focus,
.App .ant-btn-primary.disabled:focus,
.App .ant-btn-primary[disabled]:focus,
.App .ant-btn-primary-disabled:active,
.App .ant-btn-primary.disabled:active,
.App .ant-btn-primary[disabled]:active,
.App .ant-btn-primary-disabled.active,
.App .ant-btn-primary.disabled.active,
.App .ant-btn-primary[disabled].active {
  background-color: #8fafcc66;
}
.App .ant-input-disabled {
  background-color: #EBEDEE !important;
}

.App .ant-form-item {
  margin-bottom: 16px;
}

.App .ant-card-head {
  border-bottom: none;
  padding-bottom: 0;
}
.App .ant-card-head + .ant-card-body {
  padding-top: 0;
}
.App .ant-card-head-title {
  font-size: 14px;
  font-weight: bold;
}
.App .client-name {
  text-transform: capitalize;
}

.App .welcome-banner {
  background: linear-gradient(45deg, #5b2ad0 0%, #5b2ad0 20%, #0089ff 100%);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 -100px 0 #f5f5ff inset;
}
.App .banner-title {
  font-size: 40px;
  padding-top: 40px;
}

.App .progress-bar .ant-card-extra {
  min-width: calc(100% - 270px);
}

.App .doc-upload .ant-upload,
.App .doc-upload .ant-upload-list {
  float: left;
  width: 50%;
}

.App {
  .menuBar {
    .ant-menu-item {
      line-height: normal;
    }
    .item-link-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &-icon {
        margin-bottom: 10px;
      }
    }
  }
}

// Fix width dropdown
.container-dropdown-payments {
  width: auto !important;
}

// White separator breadcrump general
.ant-breadcrumb-separator {
  color: #ffffff;
}

.card-orders {
  .ant-card-body {
    padding: 0;
    margin-left: 18px;
    // text-align: center;
  }
}

@media (min-width: 768px) {
  .App .welcome-banner {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    padding-top: 128px;
    box-shadow: 0 -120px 0 #f5f5ff inset;
  }
  .App .banner-title {
    font-size: 50px;
    line-height: 58px;
    padding-top: 72px;
  }
  .App .banner-button {
    padding-left: 56px;
    padding-right: 56px;
  }
}
@media (max-width: 767px) {
  .ant-drawer {
    .mobile-menu {
      margin-right: 10px;
    }
  }
}

// Bold popover title
.ant-popover-title {
  font-weight: 700;
}

// React tags autocomplete

.react-tags {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 50px);
  height: 1px;
  margin-left: 30px;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #1c6fe8;
  font-weight: 700;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #27292a;
  font-weight: 700;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-weight: 700;
}

$pacific-50: #1c6fe8;
$stone-80: #27292a;
$stone-50: #9da5a8;
.ant-steps-label-vertical .ant-steps-item {
  flex: 1;
  .ant-steps-item-container {
    .ant-steps-item-tail {
      &::after {
        width: calc(100% - 70px);
        height: 1px;
        margin-left: 65px;
      }
    }
    .ant-steps-item-content {
      width: 100%;
      .ant-steps-item-title {
        font-weight: 700;
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .ant-steps-item-icon {
      margin-left: 92px;
    }
  }
}
.ant-steps-item-process {
  .ant-steps-item-container {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: $pacific-50;
      }
    }
  }
}
.ant-steps-item-finish {
  .ant-steps-item-container {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: $stone-80;
      }
    }
  }
}
.ant-steps-item-wait {
  .ant-steps-item-container {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: $stone-50;
      }
    }
  }
}
.App .custom-file-upload {
  display: block;
  background: #521ECE;
  border-radius: 8px;
  color: white;
  padding: 6px 8px;
  width: 228px;
  height: 36px;
  font-size: 16px;
  font-family: "Nunito Sans";
  font-weight: 700;
  border: 1px solid #521ECE;
  margin-top: 30px;
  cursor: pointer;
}

$color-light: #fff;
$eva-50: #521ece;
$stone-70: #4f5354;
$danger: #ff0000;
.ant-input-search {
  input.ant-input {
    border-radius: 8px;
  }
  span.ant-input-suffix {
    right: 20px;
  }
}
.table-products {
  .ant-table {
    background-color: $color-light;
    border-radius: 21px;
    table {
      thead.ant-table-thead {
        tr {
          th.ant-table-column-has-actions.ant-table-column-has-filters:hover
            .anticon-filter:hover,
          .ant-table-thead
            > tr
            > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
            .ant-table-filter-icon:hover {
            border-start-end-radius: 21px;
          }
        }
      }
    }
  }
  ul.ant-pagination {
    .ant-pagination-item-active {
      background-color: $pacific-50;
      a {
        color: $color-light !important;
      }
    }
    .ant-pagination-item {
      a {
        color: $stone-80;
        font-weight: 700;
        size: 12px;
        text-align: center;
      }
    }
    .ant-pagination-prev {
      padding-right: 16px;
      .ant-pagination-item-link {
        color: $stone-50 !important;
      }
    }
    .ant-pagination-next {
      padding-left: 16px;
      .ant-pagination-item-link {
        color: $stone-50 !important;
      }
    }
  }
}

.btn-primary {
  background-color: $eva-50;
  color: $color-light !important;
  a {
    color: $color-light !important;
    &:hover {
      color: $eva-50 !important;
    }
  }
  &:hover {
    border-color: $eva-50 !important;
    color: $eva-50 !important;
  }
}

.btn-default {
  background-color: $color-light !important;
  color: $stone-70 !important;
  border: 1px solid #9da5a8 !important;
  a {
    color: $stone-70 !important;
    &:hover {
      color: $color-light !important;
    }
  }
  &:hover {
    background-color: $stone-70 !important;
    color: $color-light !important;
    border-color: #8d8e8f !important;
  }
}

.btn-danger {
  background-color: $danger;
  color: $color-light !important;
  a {
    color: $color-light !important;
    &:hover {
      color: $danger !important;
    }
  }
  &:hover {
    border-color: $danger !important;
    color: $danger !important;
  }
}

.ant-dropdown {
  z-index: 1;
}

.anticon svg {
    width: 14px;
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  border-top: none;
}

.ant-popover {
  z-index: 3000;
}

.modal-medals .ant-modal-content {
  border-radius: 14px;
}

.item-link-menu.link-nav {
    padding: 10px 15px;
}